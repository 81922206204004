export default defineNuxtRouteMiddleware(async (to) => {
  const { category, deviceUri } = to.params;

  const data = await getGroupedDevicesWithArticles();

  const deviceType = categoriesMap[category as CategoryType];

  const device = (data[deviceType] || [])
    .flatMap((manufacturer) => manufacturer.devices)
    .find((device) => device.uriId === deviceUri);

  if (!device) {
    return navigateTo("/not-found");
  }

  const deviceState = useState("device", () => device);
  const manufacturerState = useState("manufacturer", () =>
    (data[deviceType] || []).find((manufacturer) =>
      manufacturer.devices.some((device) => device.uriId === deviceUri)
    )
  );
  deviceState.value = device;
  manufacturerState.value = manufacturerState.value;
});
